import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }: Theme) => {
  return createStyles({
    video: {
      borderRadius: spacing(0.5),
    }
  })
});

export default useStyles;