import * as React from 'react';
import {
  AppBar,
  Collapse,
  Container,
  IconButton,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './Header.styles';
import { Link } from 'gatsby';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  transparent?: boolean;
}

const Header = ({ transparent = false }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const isMD = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = React.useState(false);

  const [about, setAbout] = React.useState(false);

  return (
    <AppBar
      className={clsx(classes.root, { [classes.transparent]: transparent })}
      color="transparent"
      elevation={0}
      position="absolute"
    >
      <Container>
        <Toolbar>
          <Link className={classes.title} to="/">
            {transparent ? (
              <img
                height="140px"
                style={{ fill: '#FFF' }}
                src="/images/header-logo-white.svg"
                alt="Lifehouse Church Logo"
              />
            ) : (
              <img
                height="140px"
                src="/images/header-logo-black.svg"
                alt="Lifehouse Church Logo"
              />
            )}
          </Link>
          <ul
            className={clsx(
              classes.ul,
              { [classes.menu]: !isMD },
              { [classes.hoverMenu]: isMD && open },
              { [classes.hidden]: isMD && !open }
            )}
          >
            <li>
              <Link className={classes.link} to="/im-new">
                I'm New
              </Link>
            </li>
            <li>
              <Link className={classes.link} to="/next-steps">
                Next Steps
              </Link>
            </li>
            <li>
              <span
                onClick={isMD ? () => setAbout(!about) : null}
                className={classes.link}
              >
                About
              </span>
              <Collapse
                in={isMD ? about : true}
                classes={{
                  wrapper: clsx({ [classes.collapseWrapper]: !isMD }),
                }}
              >
                <ul className={clsx(classes.ul, { [classes.submenu]: !isMD })}>
                  <li>
                    <Link className={classes.link} to="/vision">
                      Vision
                    </Link>
                  </li>
                  <li>
                    <Link className={classes.link} to="/mission-values">
                      Mission & Values
                    </Link>
                  </li>
                  <li>
                    <Link className={classes.link} to="/leadership-team">
                      Leadership Team
                    </Link>
                  </li>
                  <li>
                    <Link className={classes.link} to="/dream-team">
                      Dream Team
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li>
              <Link className={classes.link} to="/events">
                Events
              </Link>
            </li>
            <li>
              <a className={classes.link} href="/give">
                Give
              </a>
            </li>
            <li>
              <Link className={classes.link} to="/resources">
                Resources
              </Link>
            </li>
            <li>
              <a
                className={classes.link}
                href="https://shop.lifehousechurch.org.za/"
                target="_blank"
              >
                Shop
              </a>
            </li>
            <li>
              <a className={classes.link} href="/contact">
                Contact
              </a>
            </li>
          </ul>
          {isMD && (
            <IconButton
              onClick={() => {
                setOpen(!open);
                setAbout(false);
              }}
              style={{ color: transparent ? '#fafafa' : '#000' }}
            >
              {open ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
