import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: '#ffffff',
      color: '#333',
    },
    ul: {
      marginTop: 0,
      marginBottom: 0,
      paddingLeft: 0,
      listStyle: 'none',
    },
    transparent: {
      backgroundColor: '#ffffff0f',
      color: '#f2f2f2',
    },
    menuButton: {
      marginRight: spacing(2),
    },
    title: {
      height: 64,
      flexGrow: 1,
      color: 'inherit',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& .st0, & .st1, & .st2': {
        fill: '#ffffff',
      },
      '& > div': {
        marginRight: spacing(),
      },
      '& a': {
        color: 'inherit',
        textDecoration: 'none',
      },
    },
    link: {
      color: 'inherit',
      textDecoration: 'none',
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: '12pt',
      lineHeight: 'inherit',
      padding: spacing(2, 2),
    },
    menu: {
      display: 'block',
      '& > li': {
        display: 'inline-block',
        position: 'relative',
        lineHeight: '63px',
        cursor: 'pointer',
      },
      '& > li:hover': {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        '& $submenu': {
          display: 'block',
        },
      },
    },
    submenu: {
      display: 'none',
      position: 'absolute',
      backgroundColor: palette.primary.main,
      width: 200,
      padding: 0,
      '& li': {
        display: 'block',
        margin: 0,
        lineHeight: 2,
        '& > a': {
          textTransform: 'capitalize',
          width: '100%',
          display: 'block',
          padding: spacing(),
        },
      },
      '& li:hover': {
        backgroundColor: palette.primary.dark,
      },
    },
    hidden: {
      display: 'none',
    },
    show: {
      display: 'flex',
    },
    hoverMenu: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      width: '100%',
      margin: '0',
      left: 0,
      top: spacing(8),
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: palette.common.white,
      color: palette.common.black,
      padding: spacing(),
      '& > li': {
        display: 'inline-block',
        position: 'relative',
        lineHeight: '45px',
        cursor: 'pointer',
        textAlign: 'center',
      },
      '& > li:hover': {
        color: palette.primary.main,
        '& li': {
          color: palette.common.black,
          '&:hover': {
            color: palette.primary.dark,
          },
        },
      },
    },
    collapseWrapper: {
      display: 'block',
    },
  })
);

export default useStyles;
