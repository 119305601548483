import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4),
      backgroundColor: '#2b323a',
      color: '#f0f0f0',
      minHeight: theme.spacing(48),
    },
    socials: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      '& a': {
        display: 'flex',
        color: '#f0f0f0',
        padding: theme.spacing(1.5),
        margin: theme.spacing(0, 1),
        backgroundColor: '#ffffff0d',
        borderRadius: 4,
        fontSize: '20pt',
      },
      '& button:hover': {
        color: '#f2f2f2',
      },
    },
    facebook: {
      '&:hover': {
        backgroundColor: '#3b5998',
      },
    },
    facebookFilled: {
      color: '#f2f2f2',
      backgroundColor: '#3b5998',
    },
    youtube: {
      '&:hover': {
        backgroundColor: '#cc181e',
      },
    },
    youtubeFilled: {
      color: '#f2f2f2',
      backgroundColor: '#cc181e',
    },
    instagram: {
      '&:hover': {
        background:
          'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
      },
    },
    instagramFilled: {
      color: '#f2f2f2',
      background:
          'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
    },
    breadcrumbs: {
      display: 'flex',
      justifyContent: 'center',
      textTransform: 'uppercase',
      fontSize: '9pt',
      letterSpacing: 1,
      marginBottom: theme.spacing(4),
      '& ol': {
        justifyContent: 'center',
      },
      '& a': {
        display: 'flex',
        color: 'inherit',
        textDecoration: 'none',
        padding: theme.spacing(0.5, 0)
      }
    },
    separator: {
      color: theme.palette.grey[500],
      margin: theme.spacing(0, 1.25),
    },
    detail: {
      width: 'unset',
      margin: 0,
      textAlign: 'center',
      '& h5': {
        letterSpacing: 1.25,
        textTransform: 'uppercase',
        color: theme.palette.grey[500],
        fontSize: '11pt',
        fontWeight: 400,
        marginBottom: theme.spacing(),
      },
      '& p': {
        fontSize: '10pt',
        fontWeight: 400,
        letterSpacing: 0.5,
      },
    },
  })
);

export default useStyles;
