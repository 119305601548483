import {
  Breadcrumbs,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'gatsby';
import * as React from 'react';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';

import useStyles from './Footer.styles';

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.socials}>
        <SocialButton variant="facebook" />
        <SocialButton variant="instagram" />
        <SocialButton variant="youtube" />
      </div>
      <Container maxWidth="sm">
        <Breadcrumbs
          className={classes.breadcrumbs}
          color="inherit"
          classes={{ separator: classes.separator }}
          maxItems={10}
        >
          <Link to="/">Home</Link>
          <Link to="/im-new">I'm New</Link>
          <Link to="/next-steps">Next Steps</Link>
          <Link to="/vision">Vision</Link>
          <Link to="/mission-values">Mission & Values</Link>
          <Link to="/leadership-team">Leadership Team</Link>
          <Link to="/give">Give</Link>
          <Link to="/resources">Resources</Link>
          <a href="https://shop.lifehousechurch.org.za/" target="_blank">
            Shop
          </a>
          <Link to="/contact">Contact</Link>
        </Breadcrumbs>
      </Container>
      <Grid className={classes.detail} justify="center" spacing={5} container>
        <Grid item>
          <Typography variant="h5">Sunday Services</Typography>
          <Typography>From 7am online</Typography>
          <Typography>Gonubie Farmers Hall @ 9am</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">Contact Us</Typography>
          <Typography>admin@lifehousechurch.org.za</Typography>
          <Typography>076 990 0359</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;

interface SocialButtonProps {
  filled?: boolean;
  variant: 'facebook' | 'instagram' | 'youtube';
  onClick?: () => void;
}

export const SocialButton = ({
  filled,
  variant,
  onClick,
}: SocialButtonProps) => {
  const classes = useStyles();

  const className = classes[variant];
  const filledClassName = filled && classes[`${variant}Filled`];

  const href =
    variant === 'facebook'
      ? 'https://www.facebook.com/lifehousechurcheastlondon/'
      : variant === 'instagram'
      ? 'https://www.instagram.com/lifehouse_church_east_london/'
      : 'https://www.youtube.com/channel/UCQpAfQjMSBi-_0zKYrK4rwQ';
  const Icon =
    variant === 'facebook'
      ? FaFacebook
      : variant === 'instagram'
      ? FaInstagram
      : FaYoutube;

  return (
    <a
      className={clsx(className, filledClassName)}
      onClick={onClick}
      href={href}
      target="_blank"
    >
      <Icon />
    </a>
  );
};
