import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import useStyles from "./YoutubeVideo.styles";

interface Size {
  width: number;
  height: number;
}

interface Props {
  className?: string;
  style?: React.CSSProperties;
  videoUrl: string;
}

const YoutubeVideo = ({ videoUrl, style, className }: Props) => {
  const classes = useStyles();

  const [size, setSize] = useState<Size>({ width: 560, height: 315 });

  useEffect(() => {
    const element = document.querySelector("#video");

    const width = element.clientWidth - 16;
    const height = (315 / 560) * width;

    setSize({ width, height });
  }, []);

  return (
    <Grid
      id="video"
      className={className}
      style={style}
      justify="center"
      container
    >
      <Grid item>
        <iframe
          className={classes.video}
          width={size.width}
          height={size.height}
          src={videoUrl}
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Grid>
    </Grid>
  );
};

export default YoutubeVideo;
